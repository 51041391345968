import React, { useState, useEffect } from "react";
import AdminSideBar from "../components/AdminSidebar";
import axios from "axios";
import { CiSearch } from "react-icons/ci";
import SaveItem from "../components/SaveItem";


export default function ManageShop(props) {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [layers, setLayers] = useState([]);
  const [itemsShop, setItemsShop] = useState([]);
  const [filteredItemsShop, setFilteredItemsShop] = useState([]);
  const [search, setSearch] = useState("");
  const [selectedItem, setSelectedItem] = useState(7);
  const [open, setOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState("");

  let scalebylayer = {
    "Mouth": {
      scale: 6,
      position: "center"
    },
    "Background": {
      scale: 2,
      position: "top"
    },
    "Eyebrows": {
      scale: 2.5,
      position: "center 30%"
    },
    "Hair": {
      scale: 1.2,
      position: "top"
    },
    "Eyes": {
      scale: 1.5,
      position: "top"
    },
    "Shoes": {
      scale: 3.5,
      position: "bottom"
    },
    "Bottom": {
      scale: 2,
      position: "bottom"
    },
    "Top": {
      scale: 2.2,
      position: "center"
    }
  }

  const resetNotification = () => {
    setTimeout(() => {
      setError("");
      setSuccess("");
    }, 4000);
  };

  useEffect(() => {
    const regex = new RegExp(search, "i");
    const filteredItems = itemsShop.filter((item) =>
      regex.test(item.layer) || item.trait.some(trait => regex.test(trait.trait))
    );
    setFilteredItemsShop(filteredItems);
  }, [itemsShop, search]);

  const handleItemClick = (index) => {
    setSelectedItem(index);
  };

  useEffect(() => {
    if (filteredItemsShop.length > 0) {
      let index = filteredItemsShop.findIndex(item => item.layer === filteredItemsShop[filteredItemsShop.length - 1].layer);
      setSelectedItem(index);
    }
  }, [filteredItemsShop]);

  const loadLayers = async () => {
    await setLoading(true);
    await axios
      .get(`https://app.themoopians.io/api/layers`)
      .then((res) => {
        setLayers(res.data.data)
        setLoading(false);
      });
  };

  const getInventorys = async () => {
    await axios({
      method: "get",
      url: `https://app.themoopians.io/api/inventorys`,
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(async (response) => {
        let itemsShopData = response.data.data;
        if (itemsShopData.length) {
          await setItemsShop(itemsShopData);
        }
      })
      .catch(async (error) => {
        console.log(error.response);
      });
  };

  const deleteItem = async (layer, trait) => {
    setError("");
    setSuccess("");
    if (window.confirm(`Are you sure you want to delete ${trait} from ${layer}?`)) {
      try {
        const response = await axios.delete(`https://app.themoopians.io/api/deleteItem`, {
          data: {
            layer: layer,
            trait: trait
          }
        });
        if (response.status === 200) {
          await setSuccess("Item deleted successfully!");
          await resetNotification();
          await getInventorys();
          setTimeout(() => {
            if (filteredItemsShop.length > 0) {
              const nextSelectedItem = filteredItemsShop.findIndex(item => item.layer === layer);
              let currentSelectedItem = (nextSelectedItem !== -1 ? nextSelectedItem : 0);
              setSelectedItem(currentSelectedItem);
            } else {
              setSelectedItem(0);
            }
          }, 500);
        } else {
          await setError("Failed to delete the item.");
          await resetNotification();
        }
      } catch (error) {
        console.error("There was an error deleting the item:", error);
        await setError("Error deleting the item.");
        await resetNotification();
      }
    }
  };

  useEffect(() => {
    loadLayers();
    getInventorys();
  }, [])

  return (
    <>
      <div className="font-Poppins flex justify-end max-w-[1920px] mx-auto">
        <AdminSideBar />
        <div className="bg-[#F8F8F8] min-h-screen w-full min-[920px]:w-[75%] xl:w-[80%] 2xl:w-[85%] dark:bg-slate-700">
          <div className="mt-[31px] mx-5 text-[#E6007C] dark:text-white">
            <div className="w-full flex justify-between gap-x-6">
              <div className="text-[#27262E]">
                <h2 className="text-[32px] font-extrabold">Shop </h2>
              </div>

              <div className="flex justify-end gap-x-6">
                <div className="min-w-[58px]">
                  <div className="absolute flex justify-center">
                    <div className="relative">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="58"
                        height="56"
                        viewBox="0 0 58 56"
                        fill="none"
                      >
                        <path
                          d="M57.5 28C57.5 43.1714 44.7568 55.5 29 55.5C13.2432 55.5 0.5 43.1714 0.5 28C0.5 12.8286 13.2432 0.5 29 0.5C44.7568 0.5 57.5 12.8286 57.5 28Z"
                          fill="white"
                          stroke="#E9E9E9"
                        />
                      </svg>
                    </div>

                    <div className="relative flex items-center -left-[46%]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="0 0 25 24"
                        fill="none"
                      >
                        <path
                          d="M20.1457 14.49L19.1041 12.83C18.8853 12.46 18.6874 11.76 18.6874 11.35V8.82C18.6874 6.47 17.2499 4.44 15.177 3.49C14.6353 2.57 13.6353 2 12.4895 2C11.3541 2 10.3332 2.59 9.79157 3.52C7.76032 4.49 6.35407 6.5 6.35407 8.82V11.35C6.35407 11.76 6.15615 12.46 5.9374 12.82L4.88532 14.49C4.46865 15.16 4.3749 15.9 4.63532 16.58C4.88532 17.25 5.47907 17.77 6.2499 18.02C8.27073 18.68 10.3957 19 12.5207 19C14.6457 19 16.7707 18.68 18.7916 18.03C19.5207 17.8 20.0832 17.27 20.3541 16.58C20.6249 15.89 20.552 15.13 20.1457 14.49Z"
                          fill="#7A797D"
                        />
                        <path
                          d="M15.4479 20.01C15.0104 21.17 13.8542 22 12.5 22C11.6771 22 10.8646 21.68 10.2917 21.11C9.95833 20.81 9.70833 20.41 9.5625 20C9.69792 20.02 9.83333 20.03 9.97917 20.05C10.2187 20.08 10.4687 20.11 10.7187 20.13C11.3125 20.18 11.9167 20.21 12.5208 20.21C13.1146 20.21 13.7083 20.18 14.2917 20.13C14.5104 20.11 14.7292 20.1 14.9375 20.07C15.1042 20.05 15.2708 20.03 15.4479 20.01Z"
                          fill="#7A797D"
                        />
                      </svg>
                    </div>

                    <div className="relative flex flex-col justify-center pb-1 -left-[58%]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <circle
                          cx="4"
                          cy="4"
                          r="3.5"
                          fill="#FC8B23"
                          stroke="white"
                        />
                      </svg>

                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="8"
                        height="8"
                        viewBox="0 0 8 8"
                        fill="none"
                      >
                        <circle cx="4" cy="4" r="3.5" fill="none" />
                      </svg>
                    </div>
                  </div>
                </div>

                <div className="w-[58px]">
                  <img src="/img/Moopianslogo.png" className="rounded-full" alt="Profile Image" />
                </div>
              </div>
            </div>

            <div className="flex items-center w-full">
              <div className="text-xs sm:text-base text-[#B9B8BC] flex ml-4 my-6 w-1/2 sm:w-full">
                <div className="flex items-center -mr-10 z-10">
                  <CiSearch className="text-2xl" />
                </div>
                <input
                  type="search"
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  className="form-control block w-2/5 pl-12 pr-6 rounded-[35px] text-[#B9B8BC] bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none py-4"
                  placeholder="Search Item and Traits..."
                />
              </div>

              <div className="w-1/2 sm:w-36" >
                <button className="px-4 py-3 bg-[#E6007C] text-white text-lg font-medium rounded-lg w-full" onClick={() => {
                  setCurrentItem("")
                  setOpen(true)
                }}>
                  Add
                </button>
              </div>
            </div>

            <div className="text-[#222222]">
              <ul className="flex flex-row-reverse justify-end items-center text-sm text-[#7A797D] mt-2">
                {filteredItemsShop.length
                  ? filteredItemsShop.map((data, index) => (
                    <li
                      key={index}
                      onClick={() => handleItemClick(index)}
                      className={`${selectedItem === index
                        ? "text-[#E6007C] bg-[#E6007C] bg-opacity-10 px-3 py-[5px] rounded-[30px]"
                        : "cursor-pointer px-3 py-[5px] rounded-[30px]"
                        }`}
                    >
                      {data.layer}
                    </li>
                  ))
                  : null
                }
              </ul>
            </div>

            <div className="flex flex-wrap my-6">
              {filteredItemsShop.length
                ? filteredItemsShop.map((data, item) => (
                  selectedItem == item
                    ? data.trait
                      .filter(trait => {
                        const regex = new RegExp(search, "i");
                        return regex.test(trait.trait);
                      }).length
                      ? data.trait
                        .filter(trait => {
                          const regex = new RegExp(search, "i");
                          return regex.test(trait.trait);
                        })
                        .map((trait, index) => (
                          <div
                            key={index}
                            className="group relative rounded-2xl my-4 bg-white p-3 w-[50%] sm:w-[49%] md:w-[32%] lg:w-[23%] lg:mx-[1%]"
                          >
                            <div className="w-full rounded-xl max-sm:flex items-center justify-center overflow-hidden">
                              <img
                                src={`https://app.themoopians.io/api/images/${data.layer}/${trait.trait}.png`}
                                alt={trait.trait}
                                className="h-[180px] object-contain rounded-2xl w-full"
                                style={{
                                  transform: `scale(${scalebylayer[data.layer]?.scale || 1})`,
                                  transformOrigin: `${scalebylayer[data.layer]?.position || 'center'}`,
                                }}
                              />
                            </div>

                            <div className="mx-3 mt-3 mb-2">
                              <h2 className="text-[16px] text-[#27262E] font-semibold">
                                {trait.trait}
                              </h2>
                            </div>
                            <div className="flex justify-between items-center mx-3">
                              <div className="flex items-center space-x-2">
                                <p className="text-[#27262E] text-xs font-medium">
                                  Quantity Sold: <span className="text-[#E6007C] font-semibold text-sm">{trait.sold}</span>
                                </p>
                              </div>
                              <div className="flex items-center space-x-2">
                                <p className="text-[#27262E] text-xs font-medium">
                                  Total Quantity: <span className="text-[#E6007C] font-semibold text-sm">{trait.quantity}</span>
                                </p>
                              </div>
                            </div>
                            <div className="flex justify-between items-center mx-3">
                              <div className="flex items-center space-x-2">
                                <p className="text-[#E6007C] text-sm font-semibold">
                                  {trait.price}
                                </p>
                                <p className="text-[#27262E] text-xs font-medium">
                                  {" "}
                                  MooLah
                                </p>
                              </div>
                            </div>
                            <div className="flex justify-between items-center mx-3">
                              <div className="flex items-center space-x-2">
                                <p className="text-[#27262E] text-xs font-medium">
                                  Deadline:
                                </p>
                                <p className="text-[#E6007C] text-sm font-semibold">
                                  {trait.deadline}
                                </p>
                              </div>
                            </div>
                            <div className="flex flex-col justify-center items-center bg-[#000000B2] h-full w-full opacity-0 transition-opacity duration-300 group-hover:opacity-100 absolute top-0 left-0 rounded-2xl">
                              <button className="text-lg h-[36px] text-white font-medium w-[80%] bg-[#E6007C] rounded-lg " onClick={() => {
                                setCurrentItem({
                                  layer: data.layer,
                                  trait: trait.trait,
                                  quantity: trait.quantity,
                                  price: trait.price,
                                  deadline: trait.deadline,
                                  sold: trait.sold
                                })
                                setOpen(true)
                              }}>
                                Edit
                              </button>
                              <button className="text-lg mt-5 h-[36px] text-white font-medium w-[80%] bg-red-600 rounded-lg " onClick={() => {
                                deleteItem(data.layer, trait.trait)
                              }}>
                                Delete
                              </button>
                            </div>
                          </div>
                        ))
                      : <p>No trait match</p>
                    : null
                ))
                : <div className="text-center w-full">No Moopians found</div>}
            </div>
          </div>
        </div>
      </div >
      {error ? <div className="w-[250px] py-2 px-5 fixed top-[80px] right-10 bg-red-600 rounded-2xl text-white font-semibold">{error}</div> : null}
      {success ? <div className="w-[250px] py-2 px-5 fixed top-[80px] right-10 bg-green-600 rounded-2xl text-white font-semibold">{success}</div> : null}
      {
        loading
          ? <div className="fixed  right-0 top-0  transform flex justify-center items-center w-full min-h-screen z-50 bg-[rgba(0,0,0,0.2)]" >
            <div className="border-t-transparent border-solid animate-spin  rounded-full border-[#E6007C] border-8 h-52 w-52"></div>
          </div>
          : null
      }
      {open
        ? <SaveItem layers={layers} currentItem={currentItem} setOpen={setOpen} getInventorys={getInventorys} />
        : null}
    </>
  );
}
