import React, { useState, useEffect } from "react";
import axios from "axios";

export default function EditNft(props) {
  const [values, setValues] = React.useState({
    nft: props.nft,
    image: props.image,
    attributes: props.attributes,
    description: props.description,
  });
  const [loading, setLoading] = useState(false);
  const [layers, setLayers] = useState([]);
  const [errorMsg, setError] = useState("");
  const [successMsg, setSuccess] = useState("");

  const setAttributes = async (e, index) => {
    let attr = values.attributes;
    if (index === 4) attr[15].value = e.target.value;
    if (index === 15) attr[4].value = e.target.value;
    attr[index].value = e.target.value;
    await setValues({ ...values, attributes: attr });
    await generateNft();
  };

  const generateNft = async () => {
    await setLoading(true);
    await axios
      .post(`https://app.themoopians.io/api/generateImage`, {
        attributes: values.attributes,
      })
      .then(async (res) => {
        if (res.data.success) {
          await setValues({ ...values, image: res.data.data });
          setSuccess("Generate NFT Succesfully");
        } else setError("Something wrong!!");
        await setLoading(false);
      });
    setTimeout(() => {
      setSuccess("");
      setError("");
    }, 5000);
  };

  const saveNft = async () => {
    await setLoading(true);
    await axios
      .post(`https://app.themoopians.io/api/saveImage`, {
        // await axios.post(`http://localhost:8081/saveImage`, {
        attributes: values.attributes,
        nft: values.nft,
        description: values.description,
      })
      .then(async (res) => {
        console.log(res);
        if (res.data.success) {
          setSuccess("Save NFT Succesfully");
          await axios
            .get(`https://metadata.sequence.app/tokens/polygon/0xC8099cD6f4e4D0049c4CD85834013D97D6F224d6/${values.nft}/refresh`)
            .then((res) => {
              console.log(res);
            });
          props.close(true);
        }
        else setError("Something wrong!!");
        await setLoading(false);
      });
    setTimeout(() => {
      setSuccess("");
      setError("");
    }, 5000);
  };

  const loadLayers = async () => {
    await setLoading(true);
    await axios
      .get(`https://app.themoopians.io/api/layers`)
      .then((res) => {
        console.log(res.data.data);
        setLayers(res.data.data)
        setLoading(false);
      });
  };

  useEffect(() => {
    loadLayers();
  }, [])

  return (
    <>
      {layers.length
        ? <div className="font-Poppins max-w-[1980px] fixed top-0 bg-white mx-auto">
          <div className="w-4/5 mx-auto">
            <div className="py-6 flex w-full justify-end items-center gap-x-1 text-[#E6007C]">
              <div className="flex items-center cursor-pointer gap-x-1" onClick={() => props.close(false)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  fill="none"
                >
                  <path
                    d="M19.2807 18.2193C19.3504 18.289 19.4056 18.3717 19.4433 18.4628C19.4811 18.5538 19.5005 18.6514 19.5005 18.7499C19.5005 18.8485 19.4811 18.9461 19.4433 19.0371C19.4056 19.1281 19.3504 19.2109 19.2807 19.2806C19.211 19.3502 19.1283 19.4055 19.0372 19.4432C18.9462 19.4809 18.8486 19.5003 18.7501 19.5003C18.6515 19.5003 18.5539 19.4809 18.4629 19.4432C18.3718 19.4055 18.2891 19.3502 18.2194 19.2806L12.0001 13.0602L5.78068 19.2806C5.63995 19.4213 5.44907 19.5003 5.25005 19.5003C5.05103 19.5003 4.86016 19.4213 4.71943 19.2806C4.5787 19.1398 4.49963 18.949 4.49963 18.7499C4.49963 18.5509 4.5787 18.36 4.71943 18.2193L10.9397 11.9999L4.71943 5.78055C4.5787 5.63982 4.49963 5.44895 4.49963 5.24993C4.49963 5.05091 4.5787 4.86003 4.71943 4.7193C4.86016 4.57857 5.05103 4.49951 5.25005 4.49951C5.44907 4.49951 5.63995 4.57857 5.78068 4.7193L12.0001 10.9396L18.2194 4.7193C18.3602 4.57857 18.551 4.49951 18.7501 4.49951C18.9491 4.49951 19.1399 4.57857 19.2807 4.7193C19.4214 4.86003 19.5005 5.05091 19.5005 5.24993C19.5005 5.44895 19.4214 5.63982 19.2807 5.78055L13.0604 11.9999L19.2807 18.2193Z"
                    fill="#E6007C"
                  />
                </svg>
                <p className="text-lg font-medium">Close</p>
              </div>
            </div>
            <form className="pt-3">
              <h1 className=" text-[40px] text-[#27262E] font-extrabold w-full">
                Moopian # {props.nft}
              </h1>
              <div className="flex flex-col md:flex-row items-center md:items-start justify-between h-full w-full gap-x-5">
                <div className="max-w-[500px] md:w-1/2 lg:w-2/5 w-full">
                  <div className="flex justify-center mt-10 w-full lg:h-2/3">
                    <img
                      src={values.image}
                      alt=""
                      className="rounded-lg w-full object-cover"
                    />
                  </div>
                </div>

                <div className="flex justify-between flex-wrap mt-10 md:w-1/2 lg:w-3/5 h-[68vh] py-7 overflow-y-auto pr-3 mb-16">
                  <div className="w-full">
                    <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#141522]">
                      <label className="font-bold">Description</label>
                      <textarea
                        onChange={(e) => setValues({ ...values, "description": e.target.value })}
                        value={values.description}
                        rows={5}
                        className="form-control block w-full px-6 py-2 mt-2 rounded-xl bg-white  bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:bg-white focus:border-[#E6007C] focus:outline-none"></textarea>
                    </div>
                  </div>
                  {layers.length
                    ? layers.map((data, index) => (
                      <div className="lg:w-[48%] w-full" key={index}>
                        {/* <p>{values.attributes[index].value}</p> */}
                        <div className="text-xs sm:text-sm font-medium leading-[14px] tracking-[-0.02em] mb-6 mt-3 sm:mt-0 w-full text-[#141522]">
                          <label className="font-bold">{data.layer}</label>
                          <select
                            value={values.attributes[index].value}
                            className="form-control block w-full px-6 py-2 mt-2 rounded-xl bg-white  bg-clip-padding border border-solid border-gray-300 transition ease-in-out m-0 focus:bg-white focus:border-[#E6007C] focus:outline-none"
                            onChange={(e) => setAttributes(e, index)}
                          >
                            {data.trait.map((imagesName, count) => (
                              <option key={count} value={imagesName}>
                                {imagesName}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                    ))
                    : null
                  }
                </div>

                {errorMsg ? <div className="w-[250px] py-2 px-5 fixed top-[80px] right-10 bg-red-600 rounded-2xl text-white font-semibold">{errorMsg}</div> : null}
                {successMsg ? <div className="w-[250px] py-2 px-5 fixed top-[80px] right-10 bg-green-600 rounded-2xl text-white font-semibold">{successMsg}</div> : null}
              </div>
            </form>
          </div>
          <div className="bg-white px-4 fixed w-full h-28 flex justify-between items-center right-0 bottom-0 z-40 shadow">
            <div
              className={`w-full md:w-[40%] min-[920px]:w-[25%] xl:w-[25%] 2xl:w-[20%] hidden lg:block`}
            >
            </div>
            <div className="w-full flex justify-between items-center">
              <h2 className="text-2xl text-[#27262E] font-extrabold w-full sm:w-1/2">
                Edit Moopian # {props.nft}
              </h2>

              <div className="flex w-full sm:w-1/2 gap-x-4 justify-end">
                <div>
                  <button className="bg-[#E6007C]  rounded-lg py-3 px-4 text-white" onClick={saveNft}>
                    <span className="text-lg font-medium">Save</span>
                  </button>
                </div>
                <div>
                  <button className="font-medium text-lg text-[#E6007C] rounded-lg px-4 py-3 bg-white border border-[#E6007C]" onClick={() => props.close(false)}>
                    <span className="text-lg font-medium">Cancel</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        : null
      }
      {loading
        ? <div className="fixed  right-0 top-0  transform flex justify-center items-center w-full min-h-screen z-50 bg-[rgba(0,0,0,0.2)]">
          <div className="border-t-transparent border-solid animate-spin  rounded-full border-[#E6007C] border-8 h-52 w-52"></div>
        </div>
        : null}
    </>
  );
}
