import React, { useState } from "react";
import axios from "axios";
import { AiOutlineClose } from "react-icons/ai";

export default function SaveModal(props) {
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");

  const resetNotification = () => {
    setTimeout(() => {
      setError("");
      setSuccess("");
    }, 4000);
  };

  const handleFileChange = async (e) => {
    const selectedFile = e.target.files[0];

    if (selectedFile && selectedFile.type !== "image/png") {
      setError("Please upload a .png file");
      await resetNotification();
      return;
    }

    const reader = new FileReader();
    const img = new Image();

    reader.onload = async (event) => {
      img.src = event.target.result;
      img.onload = async () => {
        if (img.width !== 1800 || img.height !== 1800) {
          await setError("Image dimensions must be 1800x1800 pixels");
          await resetNotification();
          return;
        }
        await setFile(selectedFile);
      };
    };

    reader.readAsDataURL(selectedFile);
  };

  const addLayers = async () => {
    if (!file) {
      await setError("Please select a valid image file before saving.");
      await resetNotification();
      return;
    }
    await setLoading(true);
    const formData = new FormData();
    await formData.append('file', file);
    const config = {
      headers: {
        'content-type': 'multipart/form-data',
        'layer': props.layer
      }
    };

    await axios
      .post(`https://app.themoopians.io/api/addImage`, formData, config)
      // .post(`http://localhost:8081/addImage`, formData, config)
      .then((res) => {
        console.log(res.data);
        props.setOpen(false)
        // setLayers(res.data.data)
        setLoading(false);
      }).catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="fixed font-Poppins top-0 left-0 z-50 h-screen w-screen flex justify-center items-center bg-[rgba(0,0,0,0.3)]">
        <div className="p-8 bg-white w-1/3 flex flex-col gap-y-8 text-sm font-medium text-[#141522] rounded-2xl">
          <div className="flex justify-end w-full"><AiOutlineClose size={23} onClick={() => props.setOpen(false)} /></div>
          <div className="space-y-2">
            <label>Upload Image</label>
            <input
              type="file"
              name="nft"
              accept=".png"
              onChange={handleFileChange}
              className="form-control block w-full rounded-[10px] text-[#B9B8BC] bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none py-4 px-5 border border-[#E2E2E4]"
            />
          </div>

          {/* <div className="space-y-2">
            <label>Rarity</label>
            <input
              type="text"
              name="nft"
              min={1}
              max={20000}
              className="form-control block w-full rounded-[10px] text-[#B9B8BC] bg-white bg-clip-padding transition ease-in-out m-0 focus:outline-none py-4 px-5 border border-[#E2E2E4]"
              placeholder="Enter Rarity"
            />
          </div> */}

          <div className="flex justify-end">
            <button onClick={addLayers} className="px-4 py-3 bg-[#E6007C] text-white text-lg font-medium rounded-lg w-1/2">
              Save
            </button>
          </div>
        </div>
      </div>
      {error ? <div className="w-[250px] py-2 px-5 fixed top-[80px] right-10 bg-red-600 rounded-2xl text-white font-semibold">{error}</div> : null}
      {success ? <div className="w-[250px] py-2 px-5 fixed top-[80px] right-10 bg-green-600 rounded-2xl text-white font-semibold">{success}</div> : null}
      {loading
        ? <div className="fixed  right-0 top-0  transform flex justify-center items-center w-full min-h-screen z-50 bg-[rgba(0,0,0,0.2)]">
          <div className="border-t-transparent border-solid animate-spin  rounded-full border-[#E6007C] border-8 h-52 w-52"></div>
        </div>
        : null}
    </>
  );
}
